<template>
  <amplify-authenticator>
    <amplify-sign-in slot="sign-in" :hide-sign-up="true">
    </amplify-sign-in>
  <v-app>
    <div
        v-if="loading"
        style="height: 100vh; width: 100vw; position: absolute; left: 0; top: 0; z-index: 9999; background: rgba(0,0,0,0.3); display: flex; justify-content: center;">
      <v-progress-circular
          :size="150"
          :width="20"
          color="blue"
          indeterminate
      ></v-progress-circular>
    </div>
    <v-navigation-drawer app permanent>

      <v-list-item align="left">
        <v-list-item-content>
          <v-list-item-title class="title">
            ReadTheory
          </v-list-item-title>
          <v-list-item-subtitle>
            BackOffice v2.0
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-content>
          <v-avatar :tile="true">
            <img :src="require('@/assets/logo.png')" alt="logo">
          </v-avatar>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list
          dense
          nav
      >
        <v-list-item
            v-for="item in allowedItems"
            :key="item.title"
            :to="item.link"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
          <amplify-sign-out></amplify-sign-out>
      </v-list>
    </v-navigation-drawer>
    <!-- Sizes your content based upon application components -->
    <v-main>

      <!-- Provides the application the proper gutter -->
      <v-container fluid>
        <notifications-widget ref="notificationWidget"></notifications-widget>
        <router-view></router-view>
      </v-container>
    </v-main>

    <v-footer app>
      <!-- -->
    </v-footer>
  </v-app>
  </amplify-authenticator>

</template>

<script>

import { Logger } from 'aws-amplify';
import NotificationsWidget from "@/components/NotificationsWidget";
import { onAuthUIStateChange } from '@aws-amplify/ui-components'
import {Auth} from "@aws-amplify/auth";
import {EventBus, NOTIFY_EVENT} from '@/eventbus'
import _ from 'lodash'
import {mapActions, mapState} from "vuex";
import {VERSION} from "@/constants";
import store from "@/store";
import {setAuthToken} from "@/api/authtoken";


export default {
  name: 'App',

  components: {
    NotificationsWidget

  },

  methods: {
    userHasAccessFilter(userGroups) {
      return function(item) {
        return !_.isEmpty(_.intersection(userGroups, [...item.groups, 'admin']))
      }
    },
    ...mapActions(['setUser', 'setGroups', 'setSession'])
  },

  computed: {
    ...mapState(['user','groups', 'loading'])
  },

  async created() {
    const logger = new Logger('app');
    const self = this;
    logger.info(`BO Ver. ${VERSION}`)
    this.unsubscribeAuth = onAuthUIStateChange(async (authState, authData) => {
      this.authState = authState;
      const session = await Auth.currentSession()
      this.setGroups(session.idToken.payload['cognito:groups'])
      this.setUser(authData)
      this.setSession(session)
      this.axios.defaults.headers.common['X-BO-Authorization'] = 'Bearer '+session.accessToken.jwtToken;
      let url = `/auth`;
      const {data, status} = await this.axios(url);
      if (status === 200) {
        setAuthToken(data);
      }

    })
    try {
      const session = await Auth.currentSession()
      this.setSession(session)
      this.axios.defaults.headers.common['X-BO-Authorization'] = 'Bearer '+session.accessToken.jwtToken;
      let url = `/auth`;
      const {data, status} = await this.axios(url);
      if (status === 200) {
        setAuthToken(data);
      }
    } catch (e) {
      logger.error(e)
      this.setGroups(null)
      this.setUser(null)
      this.setSession(null)
    }
    EventBus.$on(NOTIFY_EVENT, (event) => {
      self.$refs['notificationWidget'].notify(event.message, event.type, event.timeout)
    });
    this.$root.$on(NOTIFY_EVENT, (event) => {
      self.$refs['notificationWidget'].notify(event.message, event.type, event.timeout)
    });
    this.$router.beforeEach((to, from, next) => {
      if (to.name === 'Test') next()
      else if (to.name !== 'Home' && !store.state.session) next({ name: 'Home' })
      else next()
    })
  },


  watch: {
    groups: async function(newVal) {
      const filterFunc = this.userHasAccessFilter(newVal);
      this.allowedItems = this.items.filter(filterFunc)
      if (!_.isEmpty(this.allowedItems) && window.location.pathname === '/') {
        this.$router.push({path: this.allowedItems[0].link})
      }
    }
  },

  data: () => ({
    items: [
      { title: 'Users', icon: 'mdi-account-multiple-outline', link: '/users', groups: ['support'] },
      { title: 'Teachers', icon: 'mdi-account-star', link: '/teachers', groups: ['support'] },
      { title: 'Students', icon: 'mdi-school', link: '/students', groups: ['support'] },
      { title: 'Bulk Delete', icon: 'mdi-account-multiple-minus', link: '/bulk-delete', groups: ['support'] },
      // { title: 'Bulk Subscription', icon: 'mdi-account-multiple-plus', link: '/bulk-subs', groups: ['support'] },
      { title: 'Update ads.txt', icon: 'mdi-file-document', link: '/ads-txt', groups: ['adops'] },
      { title: 'Ad Configurations', icon: 'mdi-file-document', link: '/ad-configs', groups: ['adops'] },
      { title: 'Asc Version Deployment', icon: 'mdi-file-document', link: '/asc-deploy-version', groups: ['adops'] },
      { title: 'Subscription Plans', icon: 'mdi-file-document', link: '/sub-plans', groups: ['admin'] },
      { title: 'Passages', icon: 'mdi-file-document', link: '/passages', groups: ['admin', 'content'] },
      { title: 'Schools', icon: 'mdi-school', link: '/schools', groups: ['support'] },
      { title: 'Custom Schools', icon: 'mdi-school', link: '/custom-schools', groups: ['support'] },
      { title: 'Premium School Accounts', icon: 'mdi-school', link: '/premium', groups: ['support'] },
      // { title: 'Test', icon: 'mdi-account-multiple-minus', link: '/test', groups: ['admin'] },
    ],
    allowedItems: [],
    authState: undefined,
    unsubscribeAuth: undefined,
  }),

  beforeDestroy() {
    this.unsubscribeAuth();
  }
};
</script>
<style scoped>

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s ease
}

.fade-enter, .fade-leave-to {
  opacity: 0
}


</style>
<style>
.text-lowercase {
  text-transform: lowercase;
}

</style>
